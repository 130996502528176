<template>
  <!-- 人脸门禁 出入记录 以图搜图 -->
  <div class="box-card" style="text-align: left" v-loading.fullscreen.lock="isLoading" :element-loading-text="'上传中'"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="content_box">
      <el-form ref="form" :model="queryForm" label-width="120px">
        <el-card class="uploadCard">
          <el-upload class="avatar-uploader" accept="image/jpeg,image/jpg,image/png" action="" :http-request="upload"
            :show-file-list="false" :before-upload="beforeAvatarUpload">
            <el-image class="upLoadImg" fit="cover" :src="queryForm.img2Base64" v-if="queryForm.img2Base64"></el-image>
            <span v-else>
              <i class="el-icon-plus avatar-uploader-icon"></i>
              请上传人脸照片
            </span>
          </el-upload>
        </el-card>
        <div class="right_box">
          <el-form-item label="日期：">
            <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
              type="datetimerange" range-separator="至" start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
            </el-date-picker>
          </el-form-item>
          <div>
            <lbButton icon="shanchu" type="warning" :fill="true" v-if="queryForm.img2Base64" @click="delPic()">清 除</lbButton>
          <lbButton icon="chaxun" :fill="true" @click="searchSubmit()" class="searchButton">查 询</lbButton>
          </div>
        </div>
      </el-form>
      <el-divider></el-divider>
      <div class="totalMiddle" v-if="TotalCount">共找到{{ TotalCount }}条记录</div>
      <ul class="content_list">
        <li v-for="(item, index) in dataList" :key="index" class="content_item">
          <div class="heard_box">
            <span class="item_span">{{ item.person_name }}</span>
            <span class="similarity">相似度: <span style="color:#01A6FB;">{{ (parseInt(item.similarity * 100)) }}%</span></span>
          </div>
          <el-image fit="cover" :src="item.pic" :preview-src-list="[item.pic]" class="resultImg"></el-image>
          <span class="facesluice_name">{{ item.facesluice_name }}</span>
          <span class="go_time">{{ $moment(item.go_time).format("YYYY-MM-DD HH:mm:ss") }}</span>
        </li>
      </ul>

      <!-- 页码 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryForm.page_index" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.page_size"
        layout="total, sizes, prev, pager, next, jumper" :total="TotalCount" >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { searchFaceByFace } from '@/api/securityApi'
import request from '@/api/systemapi'
export default {
  data () {
    return {
      isLoading: false,
      timeduan: null,
      queryForm: {
        img2Base64: '', // 图片base64
        go_begintime: '', // 开始时间
        go_endtime: '', // 结束时间
        page_size: 20, // 每页行数
        page_index: 1 // 页数
      },
      dataList: [],
      TotalCount: 0
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.go_begintime = newV[0]
        this.queryForm.go_endtime = newV[1]
      } else {
        this.queryForm.go_begintime = ''
        this.queryForm.go_endtime = ''
      }
    }
  },
  methods: {
    searchSubmit () {
      this.queryForm.page_index = 1
      this.fnSearchFaceByFace()
    },
    // 搜索请求
    async fnSearchFaceByFace () {
      const res = await searchFaceByFace(this.queryForm)
      this.TotalCount = res && res.Code === 200 ? res.Data.TotalCount : 0
      // this.dataList = res && res.Code === 200 ? res.Data.DataList : []
      const rowList = res && res.Code === 200 ? res.Data.DataList : []
      // 处理数据排序
      this.dataList = rowList.sort((a, b) => { return b.similarity - a.similarity })
    },
    // 触发选择图片按钮
    upload (item) {
      console.log(item)
      var that = this
      this.isLoading = true
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      const src = window.URL.createObjectURL(item.file)
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        that.fnFaceRecognition(base64image)
      }
      reader.readAsDataURL(item.file)
    },
    // 人脸验证
    async fnFaceRecognition (baseImg) {
      const res = await request.faceRecognition({
        img2Base64: baseImg
      })
      if (res && res.Code === 200) {
        // this.$msg.success('人脸验证成功！');
        if (res.Data.face_count === 1) {
          this.queryForm.img2Base64 = baseImg
        } else {
          this.$msg.warning('人脸验证失败，存在多张人脸！')
        }
      }
      this.isLoading = false
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.page_size = size
      this.fnSearchFaceByFace()
    },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.page_index = page
      this.fnSearchFaceByFace()
    },
    delPic () {
      this.queryForm.img2Base64 = ''
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  padding: 10px;
  .content_box{
    width: 100%;
    height: 100%;
  }
}

.el-form {
  display: flex;
  margin: 10px 0 ;
  /deep/ .avatar-uploader {
    width: 170px;
    height: 170px;
    // margin-right: 30px;
    font-size: 12px;

    .el-image {
      border-radius: 8px;
      width: 170px;
      height: 170px;
    }

    .el-upload {
      width: inherit;
      height: inherit;
      border: 1px dashed #d9d9d9;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      >span {
        width: inherit;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .avatar-uploader-icon {
          font-size: 20px;
          color: #8c939d;
        }
      }
    }
  }

  /deep/ .right_box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    // .el-button {
    //   margin-top: 20px;
    // }
  }
}

.totalMiddle {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  margin-bottom: 15px;
}

.content_list {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height:500px;
  overflow-y: auto;

  .content_item {
    width: 9.5%;
    margin-right: 0.5%;
    margin-bottom: 20px;
    height: 230px;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;
    .el-image {
      // flex: 1;
      border-radius: 8px;
      // width: 100%;
      height: 120px;
    }

    .facesluice_name {
      width: 100%;
      text-align: center;
      margin-top: 5px;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }

    .go_time {
      width: 100%;
      text-align: center;
      margin-top: 5px;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }

    .heard_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .item_span {
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }

      .similarity {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}

.uploadCard {
  margin-right: 15px;
}

/deep/.el-form-item__content {
  margin-left: 60px !important;
}

/deep/.el-form-item__label {
  width: 60px !important;
  font-size: 16px;
}

/deep/.el-card__body {
  padding: 15px;
}
/deep/.el-pagination{
  width: 99%;
}
</style>
